<template>
  <div id="app" ontouchstart="">
    <ModalErrorException :show="true" :error="error" />
    <DevOnly>
      <Teleport to="body">
        <TheDebugSafeArea v-if="safeAreaTest" />
      </Teleport>
    </DevOnly>
  </div>
</template>

<script lang="ts" setup>
const error = useError();
const safeAreaTest = useRuntimeConfig().public.safeAreaTest;

// NOTE: 効かないのでコメントアウト
// setResponseStatus(404);
useHead({
  bodyAttrs: {
    id: 'body',
  },
});

</script>
