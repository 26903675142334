<template>
  <ModalAlert
    :show="props.show"
    title="エラー"
    ok-text="タイトル画面へ"
    :z-index="props.zIndex"
    @click-ok="onClick"
  >
    <div v-if="error" class="text-center">
      <div class="mb-1">
        <div>
          {{ message }}
        </div>
        タイトル画面に戻ります
      </div>
      <div>
        ※うまく動作しない場合には<br>
        アプリの再起動をお試しください
      </div>
      <div v-if="isDebugMode" class="mt-2 text-gray-400">
        ({{ error ?? '' }})
      </div>
    </div>
  </ModalAlert>
</template>

<script lang="ts" setup>
const props = withDefaults(defineProps<{
  error: Error | {statusCode: number, statusMessage: string} | never | undefined | null,
  show?: boolean,
  // eslint-disable-next-line vue/require-default-prop
  zIndex?: number,
}>(), {
  show: false,
});

interface Emits {
  (e: 'click'): void;
}
const emit = defineEmits<Emits>();

const isDebugMode = useRuntimeConfig().public.debugMode;
const message = computed(() => {
  if (!props.error) {
    return '';
  }
  if (props.error && 'statusMessage' in props.error && props.error.statusMessage) {
    return props.error.statusMessage;
  }
  return '不明なエラーが発生しました';
});
const isRedirectDone = ref<boolean>(false);
watch(() => props, (data) => {
  if (data.show) {
    isRedirectDone.value = false;
  }
});
const onClick = () => {
  if (isRedirectDone.value) {
    return;
  }
  // 致命的なエラーの場合はブラウザリロードを行う
  if (props.error && 'statusCode' in props.error && process.client) {
    if (props.error?.statusCode >= 500) {
      isRedirectDone.value = true;
      location.href = '/';
      return;
    }
  }
  clearError({ redirect: '/' });
  emit('click');
};
</script>
